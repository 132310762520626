import React from 'react'

const Header = ({ headerData }) => {
    return (
        <div className=''>
            <div className=' w-full h-50 md:h-80 flex justify-center items-center overflow-hidden'>
                <img className='w-full md:w-1/2 aspect-auto' src={headerData.header_pic} alt='header_pic' />
            </div>
            <div className='w-full flex flex-row justify-center'>
                <div className='duration-500 w-28 h-28 md:h-52 md:w-52 rounded-2xl bg-indigo-400 transition -translate-y-16 overflow-hidden'>
                    <img className='w-28 h-28 md:h-52 md:w-52 ' src={headerData.profile_pic} alt='profile_pic' />
                </div>
            </div>
            <div className='duration-500 transition -translate-y-16 md:translate-y-0 w-full text-center text-4xl font-semibold'>{headerData.business_name}</div>
        </div>
    )
}

export default Header


// 
