import React from 'react'

const SubRating = ({ ratingData }) => {
    return (
        <a href={`${ratingData.link}`}>
            <div className='md:mx-4 w-52 flex flex-col items-center'>
                <div className='bg-blue-400 h-16 w-16 rounded-full text-center flex justify-center items-center transition duration-150 ease-in-out hover:scale-110'><img className='w-8 text-white' src={ratingData.review_platform_icon} alt='icon' /></div>
                <div className='mt-2 text-center'>{ratingData.review_stars}/5</div>
                <div className=''>({ratingData.total_reviews} Ratings)</div>
            </div>
        </a>
    )
}

export default SubRating
