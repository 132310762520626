import React from 'react'


const SubPayment = ({ paymentsData }) => {
    return (
        <a href={`${paymentsData.link}`}>
            <div className='mx-4 my-4 w-28 flex flex-col items-center'>
                <div className='bg-blue-400 h-16 w-16 rounded-full text-center flex justify-center items-center transition duration-150 ease-in-out hover:scale-110'><img className='w-12' src={paymentsData.link_icon} alt='icon' /></div>
                <div className='mt-2 text-center'>{paymentsData.link_name}</div>
            </div>
        </a>
    )
}

export default SubPayment
