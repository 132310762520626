import React from 'react'
import SubSocial from './SubSocial'

const Social = ({ socialData }) => {
    return (
        <div className='w-full flex items-center justify-center'>
            <div className='flex flex-col  items-center my-2 md:my-6 w-full md:w-1/2'>
                <div className='md:my-16 flex flex-col items-center w-full'>
                    <div className='md:w-full '>
                        <div className='text-2xl md:text-3xl text-center w-full'>Social</div>
                        <div className=' text-center w-full'>Love us! Rate us</div>
                    </div>
                    <div className='flex flex-wrap justify-center  w-full mt-4'>
                        {socialData.map((social) => {
                            return (
                                <SubSocial socialData={social} key={social.link_name} />
                            )
                        })}
                    </div>
                </div>
                {/* <div className='bg-blue-200 h-60 w-60 rounded-2xl'></div> */}
            </div>
        </div>
    )
}

export default Social

//note : md:w-3/4 social bigest div
//md:flex-row md:justify-between wrapper of two divs
//md:justify-start subsocial div
//md:text-left w-52 -->w-full text div
//md:text-left w-52 -->w-fullsub text div
//md:ml-24 both text div