import React from 'react'
import SubRating from './SubRating'

const Rating = ({ ratingData }) => {

    return (
        <div className='w-full md:h-52  mt-2 md:mt-8 '>
            <div className='text-2xl md:text-3xl w-full text-center'>Ratings</div>
            <div className='w-full mt-2 flex items-center justify-center'>
                {ratingData.map((rating) => {
                    return (
                        <SubRating ratingData={rating} key={rating.review_from} />
                    )
                })}
            </div>
        </div>
    )
}

export default Rating
