import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Business from "./pages/Business";
import Error from "./pages/404.js";
import Landing from "./pages/Landing"


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Landing />} />
        <Route path="/:name" exact element={<Business />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
