import React from 'react'
import '../styles/landing.css'
import Lady from '../assets/tempImage/lady.svg'
import DigitalLogo from '../assets/logo/logofullblue.svg'

const Landing = () => {
    return (
        <div className='min-h-screen w-full bg-blue-super-light relative'>
            {/* header */}

            <div className='absolute w-full flex justify-between px-6 py-4'>
                <div className='uppercase text-3xl fontlogo tracking-wider cursor-pointer transition transform scale-y-75' >intrnet.pe</div>
                {/* 
                <div className='hidden md:block'>
                    <div className='flex headerNav uppercase tracking-wide ' >
                        <div className='px-4 cursor-pointer'>Home</div>
                        <div className='px-4 cursor-pointer'>About</div>
                        <div className='px-4 cursor-pointer'>Contact</div>
                    </div>
                </div>

                <div className='h-10 w-10 md:hidden cursor-pointer'>
                    <div className=' h-1 rounded-full'></div>
                    <div className=' h-1 rounded-full mt-2'></div>
                    <div className=' h-1 rounded-full mt-2'></div>
                </div> */}
            </div>

            {/* mid section */}

            <div className='w-full h-screen flex items-center justify-center'>
                <div className='flex flex-wrap md:w-2/4 justify-between'>
                    <div className='pl-4 md:h-96 md:flex md:items-center'>
                        <div>
                            <div className='text-4xl font-sans font-semibold  '>intrnet.pe/</div>
                            <div className='text-2xl headerNav md:mt-6 '>Aapka apna page intrnet.pe</div>
                            <div className='uppercase  headerNav bg-white w-60 py-3 rounded-xl font-extrabold text-center mt-16 cursor-pointer hidden md:block'> get your own page </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-center'>
                        <div>
                            <img className='h-96 w-96' src={Lady} alt='lady' />
                            <div className='uppercase text-2xl headerNav '>coming soon</div>
                        </div>

                    </div>
                    <div className='md:w-auto w-full flex items-center justify-center'>
                        <div className=' uppercase  headerNav bg-white w-60 py-3 rounded-xl font-extrabold text-center cursor-pointer  md:hidden mt-4'> get your own page </div>
                    </div>
                </div>
            </div>

            {/* footer */}
            <div className='absolute bottom-0 text-center pb-3 font-semibold w-full text-2xl headerNav '>
                <img className='h-14 ml-8 mb-4' src={DigitalLogo} alt='logo' />
            </div>
        </div>
    )
}

export default Landing
