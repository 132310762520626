import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams, Navigate } from "react-router-dom";
import Header from '../components/business/header/Header';
import Rating from '../components/business/rating/Rating';
import Contact from '../components/business/contact/Contact';
import Order from '../components/business/order/Order';
import Social from '../components/business/social/Social';
// import Review from '../components/business/review/Review'
import Payments from '../components/business/payments/Payments';
import Footer from '../components/business/footer/Footer';


const Business = () => {
    let params = useParams();
    let businessName = params.name
    const [data, setSetData] = useState(null)
    const [error, setError] = useState(false)


    useEffect(() => {
        axios({
            method: "get",
            url: `https://admin.intrnet.pe/page/${businessName}/`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
        })
            .then(function (response) {
                setSetData(response.data.data);
            })
            .catch(function (error) {
                setError(true)
            });

    }, [businessName])

    if (error) {
        return <Navigate to="/error" />
    }

    return (
        <div className='min-h-screen'>
            {data != null &&
                <div className='min-h-screen'>
                    <Header headerData={data.assets} />
                    {data.payment.length > 0 &&
                        <Payments paymentsData={data.payment} />
                    }
                    {data.reviews.length > 0 &&
                        <Rating ratingData={data.reviews} />
                    }
                    {/* <div className='hidden w-full md:flex justify-center items-center'><div className='border-t-2 border-gray-400 w-1/2 md:1/6'></div> </div> */}
                    {data.contact.length > 0 &&
                        <Contact contactData={data.contact} />
                    }
                    {data.order.length > 0 &&
                        <Order orderData={data.order} />
                    }
                    {data.social.length > 0 &&
                        <Social socialData={data.social} />
                    }
                    {/* {Object.keys(data.owner).length > 0 &&
                        <Review reviewData={data.owner} />
                    } */}
                    <div className='w-full flex justify-center items-center'><div className='pt-1 bg-gray-300 w-1/2 md:1/6 rounded-lg my-6'></div> </div>

                    <Footer />
                </div>
            }

            {/* for testing */}
            {console.log(data)}
        </div>
    )
}

export default Business
