import React from 'react'
import Symbol from '../../../assets/image/symbol.png'
import './footer.css'

const Footer = () => {
    return (
        <div className='w-full flex flex-col items-center overflow-hidden'>
            <div className='relative h-96 w-96 overflow-hidden'>
                <div>
                    <img className=' absolute -bottom-40 h-96 w-96' src={Symbol} alt="symbol" />
                </div>
                <div className='w-96 font-custom absolute -bottom-10 tracking-wide internetpe opacity-60 py-0 text-center bg-gradient-to-b from-gray-400 bg-clip-text text-transparent'>
                    INTERNETPE
                </div>
            </div>
        </div>
    )
}

export default Footer

