import React from 'react'
import SubContact from './SubContact'

const Contact = ({ contactData }) => {
    return (
        <div className='w-full flex items-center justify-center '>
            <div className='flex flex-col items-center my-6 w-full md:w-1/2 '>
                <div className='md:my-16 flex flex-col items-center  w-full'>
                    <div className='md:w-full '>
                        <div className='text-2xl md:text-3xl text-center w-full'>Contact</div>
                        <div className='text-center w-full'>Feel free to chat with us!</div>
                    </div>
                    <div className='flex flex-wrap justify-center  w-full mt-4'>
                        {contactData.map((contact) => {
                            return (
                                <SubContact contactData={contact} key={contact.link_name} />
                            )
                        })}
                    </div>
                </div>
                {/* <div className='bg-blue-200 h-60 w-60 rounded-2xl'></div> */}
            </div>
        </div>
    )
}

export default Contact
