import React from 'react'
import Exclamation from "../assets/image/Exclamation.svg"

const Error = () => {
    return (
        <div className='min-h-screen flex items-center justify-center bg-gray-200'>
            <div className='flex flex-col'>
                <div className='flex items-center justify-center w-full '>
                    <div className='flex'  >
                        <div><img className='h-28 mr-6' src={Exclamation} alt='img' /></div>
                        <div>
                            <div className='text-6xl font-bold text-gray-500 font-sans'>404</div>
                            <div className='text-4xl text-gray-500 font-sans'>Page not found</div>
                        </div>
                    </div>
                </div>
                <div className='uppercase font-semibold text-center text-2xl tracking-wider text-gray-600 mt-16'>Dont worry! you can get this link for yourself soon :)</div>
            </div>
        </div>
    )
}

export default Error
