import React from 'react'
import SubPayment from './SubPayment'

const Payments = ({ paymentsData }) => {
    return (
        <div className='w-full flex items-center justify-center '>

            <div className='w-full md:w-1/2'>
                <div className=' md:my-16 flex flex-col items-center'>
                    <div className='w-full'>
                        <div className='text-2xl md:text-3xl w-full text-center'>Payments</div>
                    </div>
                    <div className='flex flex-wrap justify-center w-full mt-2 md:mt-4'>
                        {paymentsData.map((payment) => {
                            return (
                                <SubPayment paymentsData={payment} key={payment.link_name} />
                            )
                        })}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Payments
